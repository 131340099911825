import React from "react"
import {Link} from "gatsby"
import styled from "styled-components"
import bluetrace_logo from "../images/bluetrace.svg"
import bluetrace_white_logo from "../images/bluetrace_white.svg"

const LogoContainer = styled(Link)`
  &&{
    display: flex;
    align-items:center;
    height: 100%;
  } 
`
const LogoImage = styled.img`
  height:100%;  
`

const LogoText = styled.svg`
  height:100%;
  font-family: Source Sans Pro, sans-serif;
  text{
    font-family: Source Sans Pro, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    fill:${props => props.IsDarkTheme?"white":"black"};
  }
`

export default function Logo(props){
  return(
    <LogoContainer className="navbar-brand" height={props.Height} to="/">
      <LogoImage
        src={props.IsDarkTheme?bluetrace_white_logo : bluetrace_logo}
        alt="BlueTrace logo"
      />
      <LogoText viewBox="0 0 110 48" IsDarkTheme={props.IsDarkTheme}>
        <text x="7" y="22">BlueTrace</text>
        <text x="7" dy="41">Protocol</text>
      </LogoText>
      {/* <LogoText Height={props.Height} IsDarkTheme={props.IsDarkTheme}>BlueTrace<br/>Protocol</LogoText> */}
    </LogoContainer>
  )
}
