import React, { useState } from "react"
import Navbar from "react-bootstrap/Navbar"
import styled from "styled-components"
import { Link } from "gatsby"

import { bt_darkblue } from "./color"
import Logo from "./logo"
import Whitepaper from "../documents/bluetrace_whitepaper.pdf"

const NavbarBackground = styled.div`
  background: ${props =>
    props.IsDarkTheme
      ? "linear-gradient(32deg, #243e95 7.45%, #499dd6 100%)"
      : "transparent"};
`
const NavbarLink = styled.li`
  font-family: "Barlow";
  font-size: 1em;
  margin: 1em 1em;
  min-height: 1.8em;
  &&& {
    a,
    a:hover,
    a:focus {
      padding: 0px;
      color: ${props => (props.IsDarkTheme ? "white" : bt_darkblue)};
      text-decoration: none;
      position: relative;
    }
    a:hover:after,
    a:focus:after {
      display: block;
      content: "";
      height: 2px;
      width: 100%;
      background-color: ${props => (props.IsDarkTheme ? "white" : bt_darkblue)};
      right: 0;
      left: 0;
      position: absolute;
      animation: grow 0.3s ease-in-out forwards;
    }
  }
`
const BrandContainer = styled.div`
  height: 4rem;
`
const CollapseButton = props => {
  return (
    <Navbar.Toggle className={props.className}>{props.children}</Navbar.Toggle>
  )
}
const StyledCollapseButton = styled(CollapseButton)`
  && {
    border: none;
    background: transparent;
    i {
      color: ${props => (props.IsDarkTheme ? "white" : bt_darkblue)};
    }
  }
`

const navItems = [
  // { title: "Coalition", link: "/coalition" },
  {
    title: "Github",
    link: "https://github.com/OpenTrace-community",
    isExternal: true,
  },
  {
    title: "White Paper",
    link: Whitepaper,
    isExternal: true,
  },
  // { title: "Policy", link: "/policy" },
  { title: "Articles", link: "/articles/index.html" },
  {
    title: "Contact Us",
    link: "mailto:info@bluetrace.io",
    isExternal: true,
  },
]

export default function BTNavbar(props) {
  const [isCollapsed, setIsCollapsed] = useState(true)

  return (
    <NavbarBackground IsDarkTheme={props.IsDarkTheme}>
      <div className="container">
        <Navbar
          collapseOnSelect
          onToggle={() => setIsCollapsed(!isCollapsed)}
          expand="md"
        >
          {props.IsDarkTheme ? (
            <BrandContainer>
              <Logo IsDarkTheme={props.IsDarkTheme} />
            </BrandContainer>
          ) : (
            <BrandContainer style={{ visibility: "hidden" }}>
              <Logo IsDarkTheme={props.IsDarkTheme} />
            </BrandContainer>
          )}

          <StyledCollapseButton IsDarkTheme={props.IsDarkTheme}>
            {isCollapsed ? (
              <i className="fas fa-bars"></i>
            ) : (
              <i className="fas fa-times"></i>
            )}
          </StyledCollapseButton>
          <Navbar.Collapse id="navbar-main" className="justify-content-end">
            <ul className="navbar-nav">
              {navItems.map((navItem, index) => (
                <NavbarLink
                  key={`link-${index}`}
                  className="nav-item"
                  IsDarkTheme={props.IsDarkTheme}
                >
                  {navItem.isExternal && (
                    <a
                      className="nav-link"
                      href={navItem.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {navItem.title}
                    </a>
                  )}
                  {!navItem.isExternal && (
                    <Link className="nav-link" to={navItem.link}>
                      {navItem.title}
                    </Link>
                  )}
                </NavbarLink>
              ))}
            </ul>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </NavbarBackground>
  )
}
