import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

// import mohLogo from "../images/moh.svg"
// import govtechLogo from "../images/govtech.svg"

// const LogoContainer = styled.div`
//   display: flex;
//   align-items: center;
// `

// const Logo = styled.img`
//   width: 100%;
// `

const FooterTop = styled.div`
  flex: 1 1 auto;
  background: linear-gradient(to right, #243e95 0%, #499dd6 100%);
  color: white;
  padding: 2rem 0;
`

const FooterBottom = styled.div`
  padding: 0.5rem 0;
  background: #e5e5e5;
  font-size: 0.7rem;
  color: #9fa9b6;
`

const FooterBottomLink = styled.a`
  &:link,
  &:visited {
    text-decoration: underline;
    color: inherit;
  }
`

export default function Footer() {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "hive_logo_with_url.png" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      site {
        buildTime(formatString: "D MMMM YYYY")
      }
    }
  `)
  return (
    <footer>
      <FooterTop>
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-10 col-xl-8">
              <p>Powered by</p>
              <div className="row">
                <div className="col-8 col-sm-6 col-md-5 col-lg-4">
                  <a href="https://www.hive.gov.sg">
                    <Img
                      fluid={data.file.childImageSharp.fluid}
                      style={{ marginBottom: "1rem" }}
                    />
                  </a>
                </div>
              </div>
            </div>
            {/* <div className="col-12 col-md-5 col-lg-4">
              <p>A collaboration between</p>
              <div className="row">
                <LogoContainer className="col-6">
                  <Logo src={mohLogo} />
                </LogoContainer>
                <LogoContainer className="col-4">
                  <Logo src={govtechLogo} />
                </LogoContainer>
              </div>
            </div> */}
          </div>
        </div>
      </FooterTop>
      <FooterBottom>
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-4 col-lg-5 col-xl-4">
              <FooterBottomLink href="https://www.tech.gov.sg/report_vulnerability/">
                Report vulnerability
              </FooterBottomLink>
            </div>
            <div className="col-12 col-md-4 col-lg-4 col-xl-5">
              Last Updated: {data.site.buildTime}
            </div>
            <div className="col-12 col-md-4 col-lg-3 col-xl-3">
              &copy; 2020 Government of Singapore
            </div>
          </div>
        </div>
      </FooterBottom>
    </footer>
  )
}
